<template>
  <div>
    <div>
      Id: {{values.order.id}}
    </div>
    <div>
      Nume client: {{values.customer.full_name}}
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderPlaced",
  props: {
    values: {

    }
  }
}
</script>

<style scoped>

</style>
