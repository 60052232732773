<template>
  <v-card>
    <v-card-title>
      Nume user
    </v-card-title>
    <v-card-text>
      <v-tabs v-model="tab">
        <v-tab>
          Istoric activitati
        </v-tab>
      </v-tabs>
      <div class="p-5">

        <v-tabs-items v-model="tab">
          <v-tab-item>
            <activity-history :user-id="currentUserID"></activity-history>
          </v-tab-item>
        </v-tabs-items>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import ActivityHistory from './components/ActivityHistory'

export default {
  name: 'View',
  components: {
    ActivityHistory
  },
  data () {
    return {
      tab: ''
    }
  },
  computed: {
    currentUserID () {
      return this.$route.params.id
    }
  },
  methods: {

  },
  created () {
  }
}
</script>

<style scoped>

</style>
