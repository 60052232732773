<template>
  <div>
    <span>
      Id-uri produse:
    </span>
    <div style="display: inline-block" v-for="(item, index) in values">
      <span>
        {{item.id}}
        <span v-if="index !== values.length - 1">
          ,
        </span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductsDeleted",
  props: {
    values: {
      type: Array
    }
  }
}
</script>

<style scoped>

</style>
