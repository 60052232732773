<template>
  <div>
    {{values.id}}
  </div>
</template>

<script>
export default {
  name: "ClientCreated",
  props: {
    values: {

    }
  }
}
</script>

<style scoped>

</style>
