<template>
  <section class="data-list-container">
    <vx-card>
      <v-data-table
        :hide-default-footer="true"
        :headers="headers"
        :items="paginatedItems.data"
        @update:sort-by="updateSortField"
        @update:sort-desc="updateSortType">

        <template v-slot:item.values="{item}">
          <div v-if="item.type === 'product_config_added'">
            <products-added :values="item.values"></products-added>
          </div>
          <div v-if="item.type === 'product_deleted'">
            <products-deleted :values="item.values"></products-deleted>
          </div>
          <div v-if="item.type === 'product_config_edited'">
            <product-edited :values="item.values"></product-edited>
          </div>
          <div v-if="item.type === 'user_deleted_customer'">
            <client-deleted :values="item.values"></client-deleted>
          </div>
          <div v-if="item.type === 'user_created_customer'">
            <client-created :values="item.values"></client-created>
          </div>
          <div v-if="item.type === 'user_customer_ordered'">
            <order-placed :values="item.values"></order-placed>
          </div>
          <div v-if="item.type === 'user_customer_message_created'">
            <client-message-created :values="item.values"></client-message-created>
          </div>
          <div v-if="item.type === 'user_customer_ordered_finished'">
            <client-order-finished :values="item.values"></client-order-finished>
          </div>
        </template>

        <template v-slot:item.created_at="{item}">
          {{item.created_at | moment("HH:mm in DD.MM.YYYY")}}
        </template>
        <template v-slot:footer>
          <v-pagination
            :length="paginatedItems.last_page"
            :total-visible="5"
            :value="currentPage"
            color="#76bd43"
            @input="changePage"
          ></v-pagination>
        </template>
      </v-data-table>
    </vx-card>
  </section>
</template>

<script>

import ProductsAdded from "./ProductsAdded";
import ProductsDeleted from "./ProductsDeleted";
import ClientDeleted from "./ClientDeleted";
import ClientCreated from "./ClientCreated";
import OrderPlaced from "./OrderPlaced";
import ProductEdited from "./ProductEdited";
import ClientMessageCreated from "./ClientMessageCreated";
import ClientOrderFinished from "./ClientOrderFinished";

export default {
  name: "ActivityHistory.vue",
  components: {
    OrderPlaced,
    ClientCreated,
    ClientDeleted,
    ProductsDeleted,
    ProductsAdded,
    ProductEdited,
    ClientMessageCreated,
    ClientOrderFinished
  },
  props: {
    userId: {
      default: false
    },
  },
  data () {
    return {
      headers: [
        {text: 'Event', value: 'label'},
        {text: 'Info', value: 'values'},
        {text: 'Data', value: 'created_at'},
      ],
      sortFiled: [],
      sortIsDesc: []
    }
  },
  computed: {
    paginatedItems () {
      return this.$store.getters['userActivityHistory/paginatedData']
    },
    currentPage () {
      return this.$store.getters['userActivityHistory/currentPage']
    },
    sortParam () {
      if (this.sortFiled.length) {
        const sortString = []
        for (let i = 0; i < this.sortFiled.length; i++) {
          sortString.push((this.sortIsDesc[i] === true ? '-' : '') + this.sortFiled[i])
        }
        return sortString.join(',')
      }
      return ''
    }
  },
  methods: {
    changePage (newPage){
      this.$store.dispatch('userActivityHistory/changePage', newPage)
    },
    updateSortField (field) {
      this.sortFiled = field
    },
    updateSortType (isDesc) {
      this.sortIsDesc = isDesc
    },
    updateFilter (searchValue, filterName) {
      if (searchValue === '') {
        this.$store.dispatch('userActivityHistory/deleteFilter', filterName)
      } else {
        this.$store.dispatch('userActivityHistory/addFilter', {filterName, searchValue})
      }
    },
    setUserId(){
      this.$store.dispatch('userActivityHistory/setCurrentUserId', this.userId)
    },
    loadItems () {
      this.$store.dispatch('userActivityHistory/loadItems')
    }
  },
  watch: {
    sortParam (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$store.dispatch('userActivityHistory/changeSort', newValue)
      }
    }
  },
  created() {
    this.setUserId()
    this.loadItems()
  }
}
</script>

<style scoped>

</style>
