<template>

</template>

<script>
export default {
  name: "ClientMessageCreated",
  props: {
    values: {

    }
  }
}
</script>

<style scoped>

</style>
